import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['bank', 'card', 'unselected', 'bankSelected', 'cardSelected']

  update(ev) {
    this.hideUnselected()

    if (ev.target.value == 'bank') {
      this.hideCard()
      this.showBank()
    } else {
      this.hideBank()
      this.showCard()
    }
  }

  showBank() {
    this.bankSelectedTargets.forEach((el) => el.removeAttribute('hidden'))
  }

  showCard() {
    this.cardSelectedTargets.forEach((el) => el.removeAttribute('hidden'))
  }

  hideUnselected() {
    this.unselectedTargets.forEach((el) => el.setAttribute('hidden', true))
  }

  hideBank() {
    this.bankSelectedTargets.forEach((el) => el.setAttribute('hidden', true))
  }

  hideCard() {
    this.cardSelectedTargets.forEach((el) => el.setAttribute('hidden', true))
  }
}

