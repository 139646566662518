import { Controller } from 'stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ['orderInput', 'form']
  static values = {
    group: String
  }

  connect() {
    this.updateOrder(null, true)

    this.sortable = Sortable.create(this.element, {
      group: this.group,
      animation: 150,
      onEnd: this.updateOrder.bind(this),
      filter: '.drag-disabled',
      forceFallback: true
    })
  }

  updateOrder(_ev, skipSubmit) {
    this.orderInputTargets.forEach((el, i) => {
      el.value = i + 1
    })

    if (this.hasFormTarget && !skipSubmit) {
      this.submitForm()
    }
  }

  async submitForm() {
    const data = new FormData(this.formTarget)

    try {
      await fetch(this.formTarget.action, {
        method: this.formTarget.method,
        headers: {
          'Accept': 'application/json',
        },
        body: data
      })

    } catch(e) {
      console.error(e)
    }
  }

  get group() {
    return this.groupValue || 'shared'
  }
}
