import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['description', 'itemTypes', 'itemTypeId', 'quantity', 'amount', 'total', 'invoiceId']

  connect() { this.updateTotal() }

  updateTotal() {
    this.totalTarget.value = `$${(this.quantity * this.amount).toFixed(2)}`

    if (this.hasInvoiceIdTarget) {
      if (this.amount > 0) {
        this.invoiceIdTarget.value = 'new'
      } else {
        this.invoiceIdTarget.value = ''
      }
    }
  }

  itemTypeChange(ev) {
    const selectedItemTypeName = ev.currentTarget.value
    const selectedItemType = this.itemTypeDataByName(selectedItemTypeName)

    if (selectedItemType) {
      if (this.descriptionTarget.value == '') {
        this.descriptionTarget.value = selectedItemTypeName
      }

      this.amountTarget.value = selectedItemType.amount_as_float.toFixed(2)
      this.itemTypeIdTarget.value = selectedItemType.id
    }

    this.updateTotal()
  }

  itemTypeDataByName(name) {
    const el = this.itemTypesTarget.querySelector(`[value="${name}"]`)

    if (el != null) {
      return {
        id: el.id,
        amount_as_float: parseFloat(el.dataset.unitPrice),
        amount: el.label
      }
    } else {
      return null
    }
  }

  get quantity() {
    return parseInt(this.quantityTarget.value) || 0
  }

  get amount() {
    return parseFloat(this.amountTarget.value) || 0
  }
}
